import React from "react"
import Layout from "../components/layout"
import Card from "../components/card"
import CardLayout from "../components/cardLayout"
import ExternalLink from "../components/externalLink"
import envelope from "../images/envelope.svg"
import styles from "./write.module.css"

const formatBody = str =>
  str.trim().split("\n").join("%0D%0A").replace(/\s/, "%20")

const defaultSubject = "[*** INSERT UNIQUE SUBJECT LINE ***]".replace(
  /\s/,
  "%20"
)

const mayors = [
  {
    mayor: "Rufino Fernandez",
    city: "Livingston",
    email:
      "rfernandez@livingstonnj.org,sklein@livingstonnj.org,aanthony@livingstonnj.org,emeinhardt@livingstonnj.org,mvieira@livingstonnj.org",
    body: `
Message: (Don't forget to replace the [x]'s with your information!)🔗
Dear Mayor Fernandez and Council Members,

My name is [YOUR NAME], and I am a resident of Livingston. While initiatives like the Livingston Committee for Diversity and Inclusion are a step in the right direction, we can and need to do more. The history of our town is rooted in one of red-lining. Livingston was red lined, meaning that Black people were legally denied mortgages for houses in our town for decades. This is true for all suburbs in the United States that had a big period of growth between the 1930s and 1950s. The legacy of red-lining is obvious when you notice how segregated Essex County is. Think about how in less than 15 minutes in the car you are in a predominantly Black neighborhood. The Oranges. Newark. Irvington. Elizabeth.  This is policy and practice. Segregation. Inequity. It was built like this. Systemic racism has sunk its roots into the soil of this town and county. In light of the urgent movement for Black lives happening across our nation, I am writing to urge you to advocate for a meaningful reallocation of Livingston's expenditures away from policing, and toward social programs that more effectively meet critical community needs. I am also writing to urge that the following proposed policies be instated into the Livingston Police Department. I also implore you to review Livingston High School's Black Student Union's List of Demands , which addresses tangible reform for the Livingston Police Department. 

Further, Campaign Zero has provided 8 policies that, at your discretion, could be enacted today. These 8 policies have demonstrated the ability to decrease police violence by 72%. The 8 policies are as follows: ban chokeholds and strangleholds, require de-escalation, require warning before shooting, exhaust all other means before shooting, duty to intervene, ban shooting at moving vehicles, require use of force continuum, and require comprehensive reporting. For more information, you can visit https://8cantwait.org/.

This is one of the many pathways toward reforming our broken systems. Please don't choose between this and your efforts to defund, prosecute, etc. This is not an "either/or," but a "both/and." Whatever it is you are doing at this time, do this too.

There is a role in the good fight for everybody. It is time for Livingston to STAND UP. I urge you to act today.

Thank you,

[YOUR NAME][YOUR ADDRESS]
[YOUR PHONE][YOUR EMAIL]
`,
  },
  {
    city: "Newark",
    mayor: "Ras Baraka",
    email:
      "barakara@ci.newark.nj.us,ramosa@ci.newark.nj.us,amadora@ci.newark.nj.us,gonzalezc@ci.newark.nj.us,osbornee@ci.newark.nj.us,jamesjoh@ci.newark.nj.us,mccallumjo@ci.newark.nj.us,mciverl@ci.newark.nj.us,quintanal@ci.newark.nj.us,crumpm@ci.newark.nj.us",
    body: `
Message: (Don't forget to replace the [x]'s with your information!)
Hello,

My name is [YOUR NAME] and I am a resident of Essex County, NJ. I urge you to redirect money away from the NPD and into social service programs that will benefit public health and our own communities. Police reforms have not solved police violence: the Minneapolis Police Department had already implemented many reforms, and George Floyd was still murdered. I urge that you take radical steps to defund the police and move towards the goal of reimagining public safety without a police department, per 8 to Abolition (8toabolition.com).

Newark's 2019 Municipal Budget shows that $154,625,090 was spent on the Division of Police. This is by far the largest fraction of the city's budget, which is over double the second largest allocation of funds (Division of Fire's $72,354,451). It's an egregious statistic compared to $9,407,451 spent on the entire Department of Health and Community Wellness, $3,504,842 on the Department of Economic and Housing Development, and just $359,239 to the Division of Cultural Affairs. If this is truly representative of the council’s thoughts on how funds should be allocated in a city in which one in every 3.5 residents live in poverty.

I am writing to demand that the budget will be spent promoting the safety and well-being of Newark's current residents, rather than arresting, displacing, or incarcerating my neighbors. We don't need a militarized police force. To fight crime, we need to create a space in which more mental health service providers, social workers, victim/survivor advocates, religious leaders, neighbors, and friends - all of the people who really make up our community - can look out for one another. I am writing to insist that the upcoming budget hearings for FY 2021 reflect the voices and needs of Newark’s citizens and that changes are made to NPD's policies.

Mayor Baraka and city councilors, please divest in and reimagine role of police in the community and INVEST in schools, youth programming, mental health, affordable housing, substance abuse treatement, etc.

Thank you,
[YOUR NAME][YOUR ADDRESS]
[YOUR EMAIL][YOUR PHONE]

Poverty source: https://www.welfareinfo.org/poverty-rate/new-jersey/newark#:~:text=The%20poverty%20rate%20in%20Newark%20is%2028.3%25.,line%20in%20the%20last%20year
`,
  },
  {
    city: "New Brunswick",
    mayor: "James M. Cahill",
    email:
      "gfleming@cityofnewbrunswick.org,kegan@cityofnewbrunswick.org,janderson@cityofnewbrunswick.org,rescobar@cityofnewbrunswick.org,ssicoraludwig@cityofnewbrunswick.org,kjones11@cityofnewbrunswick.org",
    body: `
Dear Mayor and New Brunswick Council Members, 

My name is [YOUR NAME], and I am a resident of [TOWN/NEIGHBORHOOD]. In light of the urgent movement for Black lives happening across our nation, I am writing to urge you to advocate for a meaningful reallocation of New Brunswick's expenditures away from policing, and towards social programs that more effectively meet critical community needs. I am also writing to urge that the following proposed policies be instated into the New Brunswick Police Department. 

Campaign Zero has provided 8 policies that, at your discretion, could be enacted today. These 8 policies have demonstrated the ability to decrease police violence by 72%. The 8 policies are as follows: ban chokeholds and strangleholds, require de-escalation, require warning before shooting, exhaust all other means before shooting, duty to intervene, ban shooting at moving vehicles, require use of force continuum, and require comprehensive reporting. For more information, you can visit https://8cantwait.org/.

This is one of the many pathways toward reforming our broken systems. Please don't choose between this and your efforts to defund, prosecute, etc. This is not an "either/or," but a "both/and." Whatever it is you are doing at this time, do this too.

There is a role in the good fight for everybody. I urge you to act today.

Thank you,

[YOUR NAME][YOUR ADDRESS]
[YOUR PHONE][YOUR EMAIL]
`,
  },
  {
    city: "Orange",
    mayor: "Wayne D. Warren",
    email:
      "kcoley@orangenj.gov,teason@orangenj.gov,hjjr@orangenj.gov,wmontague@orangenj.gov,jsummers@orangenj.gov,cross@orangenj.gov,awooten@orangenj.gov",
    body: `
Dear Mayor and OrangeCouncil Members, 

My name is [YOUR NAME], and I am a resident of [TOWN/NEIGHBORHOOD]. In light of the urgent movement for Black lives happening across our nation, I am writing to urge you to advocate for a meaningful reallocation of Orange's expenditures away from policing, and towards social programs that more effectively meet critical community needs. I am also writing to urge that the following proposed policies be instated into the Orange Police Department. 

Campaign Zero has provided 8 policies that, at your discretion, could be enacted today. These 8 policies have demonstrated the ability to decrease police violence by 72%. The 8 policies are as follows: ban chokeholds and strangleholds, require de-escalation, require warning before shooting, exhaust all other means before shooting, duty to intervene, ban shooting at moving vehicles, require use of force continuum, and require comprehensive reporting. For more information, you can visit https://8cantwait.org/.

This is one of the many pathways toward reforming our broken systems. Please don't choose between this and your efforts to defund, prosecute, etc. This is not an "either/or," but a "both/and." Whatever it is you are doing at this time, do this too.

There is a role in the good fight for everybody. I urge you to act today.

Thank you,

[YOUR NAME][YOUR ADDRESS]
[YOUR PHONE][YOUR EMAIL]
`,
  },
  {
    city: "East Orange",
    mayor: "Ted R. Green",
    email: "constituentservices@eastorange-nj.gov",
    body: `
Dear Mayor and East Orange Council Members, 

My name is [YOUR NAME], and I am a resident of [TOWN/NEIGHBORHOOD]. In light of the urgent movement for Black lives happening across our nation, I am writing to urge you to advocate for a meaningful reallocation of East Orange's expenditures away from policing, and towards social programs that more effectively meet critical community needs. I am also writing to urge that the following proposed policies be instated into the East Orange Police Department. 

Campaign Zero has provided 8 policies that, at your discretion, could be enacted today. These 8 policies have demonstrated the ability to decrease police violence by 72%. The 8 policies are as follows: ban chokeholds and strangleholds, require de-escalation, require warning before shooting, exhaust all other means before shooting, duty to intervene, ban shooting at moving vehicles, require use of force continuum, and require comprehensive reporting. For more information, you can visit https://8cantwait.org/.

This is one of the many pathways toward reforming our broken systems. Please don't choose between this and your efforts to defund, prosecute, etc. This is not an "either/or," but a "both/and." Whatever it is you are doing at this time, do this too.
 I urge you to act today.

Thank you,

[YOUR NAME][YOUR ADDRESS]
[YOUR PHONE][YOUR EMAIL]
`,
  },
  {
    city: "Paterson",
    mayor: "Andre Sayegh",
    email: "mayorsayegh@patersonnj.gov",
    body: `
Dear Mayor and Paterson Council Members, 

My name is [YOUR NAME], and I am a resident of [TOWN/NEIGHBORHOOD]. In light of the urgent movement for Black lives happening across our nation, I am writing to urge you to advocate for a meaningful reallocation of Paterson's expenditures away from policing, and towards social programs that more effectively meet critical community needs. I am also writing to urge that the following proposed policies be instated into the Paterson Police Department. 

Campaign Zero has provided 8 policies that, at your discretion, could be enacted today. These 8 policies have demonstrated the ability to decrease police violence by 72%. The 8 policies are as follows: ban chokeholds and strangleholds, require de-escalation, require warning before shooting, exhaust all other means before shooting, duty to intervene, ban shooting at moving vehicles, require use of force continuum, and require comprehensive reporting. For more information, you can visit https://8cantwait.org/.

This is one of the many pathways toward reforming our broken systems. Please don't choose between this and your efforts to defund, prosecute, etc. This is not an "either/or," but a "both/and." Whatever it is you are doing at this time, do this too.

There is a role in the good fight for everybody. I urge you to act today.

Thank you,

[YOUR NAME][YOUR ADDRESS]
[YOUR PHONE][YOUR EMAIL]
`,
  },
  {
    city: "Elizabeth",
    mayor: "J. Christian Bollwage",
    email: "webmaster@elizabethnj.org,kmartins@elizabethnj.org",
    body: `
Dear Mayor and Ellizabeth Council Members, 

My name is [YOUR NAME], and I am a resident of [TOWN/NEIGHBORHOOD]. In light of the urgent movement for Black lives happening across our nation, I am writing to urge you to advocate for a meaningful reallocation of Elizabeth's expenditures away from policing, and towards social programs that more effectively meet critical community needs. I am also writing to urge that the following proposed policies be instated into the Elizabeth Police Department. 

Campaign Zero has provided 8 policies that, at your discretion, could be enacted today. These 8 policies have demonstrated the ability to decrease police violence by 72%. The 8 policies are as follows: ban chokeholds and strangleholds, require de-escalation, require warning before shooting, exhaust all other means before shooting, duty to intervene, ban shooting at moving vehicles, require use of force continuum, and require comprehensive reporting. For more information, you can visit https://8cantwait.org/.

This is one of the many pathways toward reforming our broken systems. Please don't choose between this and your efforts to defund, prosecute, etc. This is not an "either/or," but a "both/and." Whatever it is you are doing at this time, do this too.

There is a role in the good fight for everybody. I urge you to act today.

Thank you,

[YOUR NAME][YOUR ADDRESS]
[YOUR PHONE][YOUR EMAIL]
`,
  },
  {
    city: "Bloomfeild",
    mayor: "Micheal J. Venezia",
    email: "daviles@bloomfieldtwpnj.com,bloomfieldcourt@bloomfieldtwpnj.com",
    body: `
Dear Mayor and Bloomfield Council Members, 

My name is [YOUR NAME], and I am a resident of [TOWN/NEIGHBORHOOD]. In light of the urgent movement for Black lives happening across our nation, I am writing to urge you to advocate for a meaningful reallocation of Bloomfield's expenditures away from policing, and towards social programs that more effectively meet critical community needs. I am also writing to urge that the following proposed policies be instated into the Bloomfield Police Department. 

Campaign Zero has provided 8 policies that, at your discretion, could be enacted today. These 8 policies have demonstrated the ability to decrease police violence by 72%. The 8 policies are as follows: ban chokeholds and strangleholds, require de-escalation, require warning before shooting, exhaust all other means before shooting, duty to intervene, ban shooting at moving vehicles, require use of force continuum, and require comprehensive reporting. For more information, you can visit https://8cantwait.org/.

This is one of the many pathways toward reforming our broken systems. Please don't choose between this and your efforts to defund, prosecute, etc. This is not an "either/or," but a "both/and." Whatever it is you are doing at this time, do this too.

There is a role in the good fight for everybody. I urge you to act today.

Thank you,

[YOUR NAME][YOUR ADDRESS]
[YOUR PHONE][YOUR EMAIL]
`,
  },
  {
    city: "Montclair",
    mayor: "Robert D. Jackson",
    email:
      "info@montclairnjusa.org,bscantlebury@montclairnjusa.org,tstafford@montclairnjusa.org",
    body: `
Dear Mayor and Montclair Council Members, 

My name is [YOUR NAME], and I am a resident of [TOWN/NEIGHBORHOOD]. In light of the urgent movement for Black lives happening across our nation, I am writing to urge you to advocate for a meaningful reallocation of Montclair's expenditures away from policing, and towards social programs that more effectively meet critical community needs. I am also writing to urge that the following proposed policies be instated into the Montclair Police Department. 

Campaign Zero has provided 8 policies that, at your discretion, could be enacted today. These 8 policies have demonstrated the ability to decrease police violence by 72%. The 8 policies are as follows: ban chokeholds and strangleholds, require de-escalation, require warning before shooting, exhaust all other means before shooting, duty to intervene, ban shooting at moving vehicles, require use of force continuum, and require comprehensive reporting. For more information, you can visit https://8cantwait.org/.

This is one of the many pathways toward reforming our broken systems. Please don't choose between this and your efforts to defund, prosecute, etc. This is not an "either/or," but a "both/and." Whatever it is you are doing at this time, do this too.

There is a role in the good fight for everybody. I urge you to act today.

Thank you,

[YOUR NAME][YOUR ADDRESS]
[YOUR PHONE][YOUR EMAIL]
`,
  },
  {
    city: "Jersey City",
    mayor: "Steven Fulop",
    email:
      "info@stevenfulop.com,Fulops@jcnj.org,jwatterman@jcnj.org,RLavarro@jcnj.org,DRivera@jcnj.org,DRidley@jcnj.org,MPrinz-Arey@jcnj.org,RBoggiano@jcnj.org,YSaleh@jcnj.org,JSolomon@jcnj.org,JDRobinson@jcnj.org",
    body: `
Message: (Don't forget to replace the [x]'s with your information!)
Dear Mayor Fulop and Jersey City Council Members:

My name is [YOUR NAME]. While I applaud Mayor Fulop’s commitment to the Obama Foundation pledge to review and reform police use of force policies, I don’t believe this is enough. Police reforms do not necessarily eliminate police violence: the Minneapolis Police Department had already implemented many reforms, and George Floyd was still murdered. I urge that you take radical steps to defund the police and move towards the goal of reimagining public safety without a police department.

Jersey City’s proposed 2020 Municipal Budget has $111,390,803 allocated to the Division of Police. This is by far the largest fraction of the city’s budget, and is nearly 50% higher than the second largest allocation of funds (Division of Fire’s $76,744,661). It’s an egregious statistic compared to $41,306,177 proposed for the entire Department of Public Works; $8,569,377 for the Department of Housing, Economic Development & Commerce; and $5,282,056 for the Department of Health & Human Services. The allocation for the police alone takes up nearly 20% of the entire $612 million budget.

I am writing to demand that the 2020-2021 Jersey City municipal budget be revised to reallocate funds away from police and towards things like education, healthcare infrastructure (including non-coercive mental healthcare), youth programming, and non-coercive drug and alcohol treatment programming.

Mayor Fulop and city councilors, please consider making these changes that are in the best interest of our community now and for the future.

Thank you,

[YOUR NAME][YOUR ADDRESS]
[YOUR PHONE][YOUR EMAIL]
`,
  },
]

const counties = [
  {
    name: "Bergen County",
    email:
      "countyexecutive@co.bergen.nj.us,mamoroso@co.bergen.nj.us,jvoss@co.bergen.nj.us,DavidLGanz@co.bergen.nj.us,gortiz@co.bergen.nj.us,tsullivan@co.bergen.nj.us,Tracyzur@co.bergen.nj.us,Prosecutor@bcpo.net,dsands@bcsd.us",
    body: `
Message: (Don't forget to replace the [x]'s with your information!)
To County Executive Tedesco, Board of Freeholders, County Prosecutor Musella, and County Sheriff Cureton,

My name is [YOUR NAME]. I am writing to demand that County Executive Tedesco and the Bergen County Board of Freeholders adopt a city budget that prioritizes community wellbeing, and redirects funding away from the police.

This past month, the country has been gripped by protests demanding reconsideration of the role of police in America and an end to racism and anti-Blackness. Bergen County is not immune to the violence of racism in this country: Garfield resident Malik Williams was murdered by a police officer in 2011. His death is just one of many instances of police brutality in Bergen County, with several more instances of violence, abuse, and death at the hands of Bergen County officers. The County must do better. Moving funds away from the sheriff’s office and police department is a crucial way to combat these issues at the county level.

The 2019 budget passed by County Exec. Tedesco and the Board of Freeholders appropriated almost 80 million dollars to the Sheriff’s Office, which is 15% of the county’s total budget [1]. At the same time, less than 5% of the county’s budget was spent on public works, parks, social, and psychiatric services [1]. While the budget for 2020 has not been made public, the appropriations in the 2019 budget highlight a severe imbalance in priorities. I demand that the County Board and County Executive begin to meaningfully defund the Sheriff’s Office and redirect funds to community-based mental health services, substance abuse treatment services, affordable housing programs, and more. I demand a budget that supports community wellbeing, instead of one that empowers police forces to tear communities apart.

Sincerely,

[YOUR NAME][YOUR ADDRESS]
[YOUR EMAIL][YOUR PHONE NUMBER]

 [1] https://co.bergen.nj.us/fiscal-operations/county-budgets?download=831:bc-budget-2019
`,
  },
  {
    name: "Essex County",
    email: "joedi@admin.essexcountynj.org,palagia@admin.essexcountynj.org",
    body: `
Message: (Don't forget to replace the [x]'s with your information!)🔗 

To County Executive DiVincenzo, Board of Freeholders, County Prosecutor, and County Sheriff, 

My name is [YOUR NAME]. I am from Livingston and I am writing to demand that County Executive Tedesco and the Essex County Board of Freeholders adopt a city budget that prioritizes community wellbeing, and redirects funding away from the police. This past month, the country has been gripped by protests demanding reconsideration of the role of police in America and an end to racism and anti-Blackness. Essex County is not immune to the violence of racism in this country. The County must do better. Moving funds away from the sheriff’s office and police department is a crucial way to combat these issues at the county level. The proposed 2020 budget by County Exec. DiVincenzo and the Board of Freeholders, totaling 769.9 million dollars, should allocate less funding toward the sheriff and police department and more toward community initiatives.  I demand that the County Board and County Executive begin to meaningfully defund the Sheriff’s Office and redirect funds to community-based mental health services, substance abuse treatment services, affordable housing programs, and more. 
Further, Campaign Zero has provided 8 policies that, at your discretion, could be enacted today across the county. These 8 policies have demonstrated the ability to decrease police violence by 72%. The 8 policies are as follows: ban chokeholds and strangleholds, require de-escalation, require warning before shooting, exhaust all other means before shooting, duty to intervene, ban shooting at moving vehicles, require use of force continuum, and require comprehensive reporting. For more information, you can visit https://8cantwait.org/.

There is a role in the good fight for everybody. I urge you to act today.

Thank you,

[YOUR NAME][YOUR ADDRESS]
[YOUR PHONE][YOUR EMAIL]
`,
  },
  {
    name: "Middlesex County",
    email:
      "laura.cartwright@mail.house.gov,NJ12BWIMA@mail.house.gov,senbateman@njleg.org,asmfreiman@njleg.org,asmzwicker@njleg.org,senthompson@njleg.org,asmclifton@njleg.org,asmdancer@njleg.org,sengreenstein@njleg.org,asmbenson@njleg.org,asmdeangelo@njleg.org,senbsmith@njleg.org,asmdanielsen@njleg.org,AsmEgan@njleg.org,sendiegnan@njleg.org,asmkarabinchak@njleg.org,aswpinkin@njleg.org,senvitale@njleg.org,AsmCoughlin@njleg.org,aswlopez@njleg.org,senscutari@njleg.org,aswcarter@njleg.org,asmkennedy@njleg.org",
    body: `
Message: (Don't forget to replace the [x]'s with your information!)🔗
Dear Middlesex County Representatives,

My name is [YOUR NAME] and I am a resident of [YOUR NEIGHBORHOOD/DISTRICT]. I am writing to demand that the Middlesex County adopt a budget that prioritizes community wellbeing, and redirects funding away from the police system, as it disproportionately violates the rights and lives of Black community members - like Qwason Campbell who was assaulted by Woodbridge PD on June 1.

I demand that the Middlesex County Representatives begin meaningfully defunding all New Jersey Police Departments and re-allocate those funds to programs proven to more effectively promote a safe and equitable community: community-based mental health services, substance abuse treatment services, affordable housing programs, and more. I demand a budget that reflects the actual needs of Central New Jersey residents.

History has shown that police “reform” is not enough. We must take a hard look at the ways that the current system in place fails to serve–and in fact actively harms–our community, and come together to reimagine the role of police in our city.

Thank you for your time,
[YOUR NAME][YOUR ADDRESS]
[YOUR EMAIL][YOUR PHONE NUMBER]
`,
  },
  {
    name: "Morris County",
    email: "prosecutor@co.morris.nj.us,ccemail@co.morris.nj.us",
    body: `
Message: (Don't forget to replace the [x]'s with your information!)

To County Clerk, Board of Freeholders, County Prosecutor, and County Sheriff,

My name is [YOUR NAME]. I am from Livingston and I am writing to demand that Morris County administration intervene and seek justice for Amani Kildea, a 20 year old young man murdered in Morristown and reform police so that Black lives stop being taken and instead, matter and thrive.

Amani Kildea was a 20 year old young man found hanging in a tree in Morristown, NJ. His death, without investigation, was ruled a suicide. Amani had goals and dreams to be in the FBI or CIA one day. He was set to attend James Madison University in the fall of 2020. Amani was found an hour away from where he lives. This clearly could have been a racially charged lynching and Amani deserves better than to have his death ruled a suicide without further investigation.

Furhter, adopt a county budget that prioritizes community wellbeing, and redirects funding away from the police. This past month, the country has been gripped by protests demanding reconsideration of the role of police in America and an end to racism and anti-Blackness. Essex County is not immune to the violence of racism in this country. The County must do better. Moving funds away from the sheriff’s office and police department is a crucial way to combat these issues at the county level. I demand that the County Board and County Executive begin to meaningfully defund the Sheriff’s Office and redirect funds to community-based mental health services, substance abuse treatment services, affordable housing programs, and more.

Campaign Zero has provided 8 policies that, at your discretion, could be enacted today across the county. These 8 policies have demonstrated the ability to decrease police violence by 72%. The 8 policies are as follows: ban chokeholds and strangleholds, require de-escalation, require warning before shooting, exhaust all other means before shooting, duty to intervene, ban shooting at moving vehicles, require use of force continuum, and require comprehensive reporting. For more information, you can visit https://8cantwait.org/.

There is a role in the good fight for everybody. I urge you to act today.

Thank you,

[YOUR NAME][YOUR ADDRESS]
[YOUR PHONE][YOUR EMAIL]
`,
  },
]

const Write = () => (
  <Layout>
    <Card>
      <CardLayout headerImageSrc={envelope}>
        <h1>Write Your Elected Officials</h1>

        <p>
          See below for our NJ respresentatives. Click the links for an
          automated email to send. Personalize & sign your name!
        </p>

        <div className={styles.linksContainer}>
          <div className={styles.column}>
            <h3>NJ Senators</h3>
            <ul>
              <li>
                <ExternalLink to="https://www.booker.senate.gov/contact/write-to-cory">
                  Cory Booker
                </ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://www.menendez.senate.gov/contact">
                  Bob Menendez
                </ExternalLink>
              </li>
            </ul>

            <h3>NJ Representatives</h3>
            <ul>
              <li>
                <ExternalLink to="https://sherrill.house.gov/contact/email-me">
                  Mikie Sherrill
                </ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://malinowski.house.gov/contact">
                  Tom Malinowski
                </ExternalLink>
              </li>
            </ul>

            <h3>County Executives</h3>
            <ul>
              {counties.map(({ name, email, body }) => (
                <li key={name}>
                  <a
                    href={`mailto:${email}?subject=${defaultSubject}&body=${formatBody(
                      body
                    )}`}
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.column}>
            <h3>Town & City Mayors</h3>
            <ul>
              {mayors.map(({ city, email, body }) => (
                <li key={city}>
                  <a
                    href={`mailto:${email}?subject=${defaultSubject}&body=${formatBody(
                      body
                    )}`}
                  >
                    {city}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </CardLayout>
    </Card>
  </Layout>
)

export default Write
