import React from "react"
import { Link } from "gatsby"
import styles from "./card.module.css"

const Card = ({ children, className, to }) => {
  const outerClass = [styles.container, className].filter(Boolean).join(" ")
  const innerClass = [styles.card, to ? styles.clickable : null]
    .filter(Boolean)
    .join(" ")
  const renderLink = () => {
    if (!to) return null

    return <Link to={to} className={styles.clickableOverlay} />
  }

  return (
    <div className={outerClass}>
      <div className={innerClass}>
        {renderLink()}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}

export default Card
