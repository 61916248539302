import React from "react"
import styles from "./cardLayout.module.css"

const CardLayout = ({ children, headerImageSrc }) => (
  <div className={styles.row}>
    <div className={styles.imageColumn}>
      <img className={styles.headerImage} src={headerImageSrc} alt="" />
    </div>

    <div className={styles.contentColumn}>{children}</div>
  </div>
)

export default CardLayout
